




























































import { Style } from '@/includes/types/Post.types'
import { removeStyle } from '@/includes/logic/Bot/Styles'
import { Board } from '@/includes/types/Board.types'
import { setPostBoardConfig } from '@/includes/logic/Bot/BotConfig'

import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import Component from 'vue-class-component'
import Vue from 'vue'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    PageTitle,
    EmptyData
  }
})
export default class BoardStylesList extends Vue {
  stylesList: Record<string, Style> = {}

  isLoading = false

  get sortedStyles(): Record<string, Style> {
    const styleKeys = Object.keys(this.stylesList).sort((a, b) => a.localeCompare(b))
    const styleDict = {}

    styleKeys.forEach(k => styleDict[k] = this.stylesList[k])

    return styleDict
  }

  toStyleCreatePage(): void {
    this.$router.push({
      name: 'Style',
      params: {
        actionType: 'new'
      },
    })
  }

  toStyleEditPage(styleId: string): void {
    this.$router.push({
      name: 'Style',
      params: {
        actionType: 'edit',
      },
      query: {
        styleId
      }
    })
  }

  toStyleCopyPage(styleId:string):void {
    this.$router.push({
      name: 'Style',
      params: {
        actionType: 'copy',
      },
      query: {
        styleId
      }
    })
  }

  removeStyleWarn(styleId: string): void {
    this.$confirm({
      title: this.$t('remove_style_warn_title').toString(),
      content: this.$t('remove_style_warn_text').toString(),
      okText: this.$t('accept').toString(),
      okType: 'danger',
      cancelText: this.$t('reject').toString(),
      onOk: () => this.removeStyle(styleId)
    })
  }

  removeStyle(styleId: string): void {
    this.isLoading = true

    const { activeBoard }: { activeBoard: Board } = this.$store.state.boardsState

    if (activeBoard && activeBoard.config.styles) {
      const styles = removeStyle(activeBoard.config.styles, styleId)

      setPostBoardConfig(activeBoard.board, {
        lang: activeBoard.lang,
        timezone: activeBoard.timezone,
        config: { ...activeBoard.config, styles },
      })
        .then(res => {
          if (res) {
            this.initLocalStyles()
          }
        })
    }
  }

  initLocalStyles(): void {
    this.isLoading = true

    const { activeBoard } = this.$store.state.boardsState

    if (activeBoard) {
      this.stylesList = cloneDeep(activeBoard.config.styles)
    }

    this.isLoading = false
  }

  mounted(): void {
    this.initLocalStyles()
  }
}
